import React, {useEffect, useState} from "react";
import {Icon, IconButton, Paper, TableBody, TableContainer, TextField} from "@material-ui/core";
import {Field} from "formik";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {FlightPricesTableStyles} from "../flights.styles";
import {FetchSectorTypes} from "app/types/service-types/SectorPricesTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {addNewBlankSector, removeTheSector, setTheFlightSector} from "../store/flights-list/flight-form.slice";
import PricesTable from "./PricesTable";
import {useTranslation} from "react-i18next";

interface FlightSectorsTableProps {
    sectorsInvalidNumberOfSeatsIds?: string[];
}

const FlightSectorsTable = ({sectorsInvalidNumberOfSeatsIds = []}: FlightSectorsTableProps) => {
    const classes = FlightPricesTableStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const numberOfSeatsInForm = useSelector((state: AppState) => state.flights.flightsList.flightForm.numberOfSeats);
    const {sectorPrices} = useSelector((state: AppState) => state.flights.flightsList.flightForm);
    const [totalSeats, setTotalSeats] = useState(0);
    const [isSeatCountValid, setIsSeatCountValid] = useState(false);

    useEffect(() => {
        const total = sectorPrices.reduce((sum: number, sector: {
            numberOfSeats: string
        }) => sum + (parseInt(sector.numberOfSeats) || 0), 0);
        setTotalSeats(total);
        setIsSeatCountValid(total == numberOfSeatsInForm);
    }, [sectorPrices, numberOfSeatsInForm, totalSeats]);

    const handleChange = (e: any, sectorId: string, sector: any) => {
        dispatch(
            setTheFlightSector({
                sectorId,
                sectorPayload: {
                    name: e.target.name,
                    value: e.target.value,
                },
            })
        );
    };

    const isNumberOfSeatsValid = (sectorId: string): boolean => {
        return !sectorsInvalidNumberOfSeatsIds.includes(sectorId);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>
                            {t("app.main.flights.flightPriceTable.table.tableRow1", "Sector")}
                        </TableCell>
                        <TableCell style={{fontWeight: "bold"}} align="right">
                            {t("app.main.flights.create.form.field10", "Number of seats")}
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sectorPrices.map((sp: FetchSectorTypes, idx: number) => {
                        const {id, name, prices, numberOfSeats, sector} = sp;

                        let modifiedSector;
                        if (sector === 0) {
                            modifiedSector = idx + 1;
                            dispatch(
                                setTheFlightSector({
                                    sectorId: id,
                                    sectorPayload: {
                                        name: "sector",
                                        value: modifiedSector,
                                    },
                                })
                            );
                        } else modifiedSector = sector;

                        return (
                            <TableRow key={id + idx} className={classes.tableRow}>
                                <TableCell colSpan={3}>
                                    <div className={classes.sectorTableHeader}>
                                        <div style={{flex: 1}}>
                                            <Field
                                                type="phone"
                                                name="sector"
                                                value={modifiedSector}
                                                style={{display: "none"}}
                                            />
                                            <p>
                                                <strong>{t("app.main.flights.flightPriceTable.table.tableRow2", "Prices for the Sector")} #{modifiedSector}:</strong>
                                            </p>
                                            <p>{t("app.main.flights.flightPriceTable.table.tableRow3", "Prices for OW and RT with taxes")}</p>
                                            <p>{t("app.main.flights.flightPriceTable.table.tableRow4", "Fees will be included upon booking")}</p>
                                        </div>
                                        <div style={{flex: 0.4, textAlign: "right"}}>
                                            <Field
                                                name="numberOfSeats"
                                                fullWidth
                                                render={() => (
                                                    <TextField
                                                        fullWidth
                                                        inputProps={{
                                                            style: {width: "100%"},
                                                        }}
                                                        type="number"
                                                        variant="outlined"
                                                        size="small"
                                                        name="numberOfSeats"
                                                        label={t("app.main.flights.create.form.field10", "Number of seats")}
                                                        value={numberOfSeats}
                                                        error={!isNumberOfSeatsValid(id)}
                                                        required
                                                        onChange={(e: any) => handleChange(e, id, sp)}
                                                        helperText={
                                                            isNumberOfSeatsValid(id)
                                                                ? ""
                                                                : `${t("app.main.flights.flightPriceTable.table.validation1", "The number of seats must be 0 or more")}`
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div style={{flex: 0.2, textAlign: "right"}}>
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(removeTheSector(id));
                                                }}
                                                size="small"
                                                disabled={sectorPrices.length === 1}
                                            >
                                                <Icon color="error">delete</Icon>
                                            </IconButton>
                                        </div>
                                    </div>
                                    <PricesTable prices={prices} sectorId={id}/>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3} style={{color: isSeatCountValid ? 'green' : 'red'}}>
                            {isSeatCountValid
                                ? t("app.main.flights.flightPriceTable.table.validation3", "Total number of seats is valid.")
                                : t("app.main.flights.flightPriceTable.table.validation2", "Total number of seats does not match the form value.")}
                        </TableCell>
                    </TableRow>
                    <TableRow
                        onClick={() => {
                            dispatch(
                                addNewBlankSector(
                                    `${Math.random() * (sectorPrices.length + 1)}`
                                )
                            );
                        }}
                        className={classes.addNewTravelerRow}
                    >
                        <TableCell colSpan={10} align="center">
                            <IconButton disabled={sectorPrices.length === 1} size="small">
                                <Icon>add</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FlightSectorsTable;
