import React, {useEffect, useState} from "react";
import {Button, InputAdornment, MenuItem, TextField,} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider, TimePicker,} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {Field, Form, Formik} from "formik";
import {flightsFormStyles} from "../flights.styles";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory} from "react-router-dom";
import {AppState} from "app/store";
import {CreateFlightRequestTypes} from "app/types/service-types/FlightTypes";
import {AirlineCompany} from "app/types/service-types/AirlineCompaniesTypes";
import {createFlight} from "../store/flights-list/create-flight.slice";
import {fetchAllAirlines} from "app/store/App/airlines-list.slice";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import {fetchAllAirports} from "app/store/App/airports-list.slice";
import DateUtils from "app/utils/DateUtils";
import FlightSectorsTable from "../flight-prices-table/FlightSectorsTable";
import {resetTheFlightForm, setFlightForm, setTheFlightForm,} from "../store/flights-list/flight-form.slice";
import {errorMsg, warningMsg} from "../../../utils/MessageUtils";
import {fetchAllCurrencies} from "../store/currencies-list.slice";
import {FareFlightTable} from "../fare-flight-table/FareFlightTable";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
    numberOfSeats: yup
        .number()
        .typeError("Duhet të jetë numër")
        .test(
            "min",
            "Numri i ulëseve duhet të jetë 0 apo më shumë",
            (val: any) => val >= 0
        ),
    maxOnline: yup
        .number()
        .typeError("Duhet të jetë numër")
        .test(
            "min",
            "Numri i MaxOnline duhet të jetë 1 apo më shumë",
            (val: any) => val > 0
        ),
});

const CreateFlightForm = () => {
    const classes = flightsFormStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const createFlightsState = useSelector((state: AppState) => state.flights.flightsList.createFlight);
    const {airlineCompanies} = useSelector((state: AppState) => state.appReducers.airlines);
    const airports = useSelector((state: AppState) => state.configurations.airport.airportsList.airports.content);
    const flightForm = useSelector((state: AppState) => state.flights.flightsList.flightForm);
    const {currencies} = useSelector((state: AppState) => state.flights.currenciesList);
    const [sectorsInvalidNumberOfSeatsIds, setSectorsInvalidNumberOfSeatsIds] = useState<string[]>([]);

    useEffect(() => {
        setFlightForm({
            ...flightForm,
            economyWithRestrictions: {
                ...flightForm.economyWithRestrictions,
                noOfSeats: flightForm.numberOfSeats,
            },
            economyPremium: {
                ...flightForm.economyPremium,
                noOfSeats: 15,
            },
            economyWithoutRestrictions: {
                ...flightForm.economyWithoutRestrictions,
                noOfSeats: 15,
            },
        });
        dispatch(fetchAllAirlines());
        dispatch(fetchAllAirports());
        dispatch(fetchAllCurrencies());
    }, []);

    const [maxOnline, setMaxOnline] = useState(flightForm.numberOfSeats);

    const handleChangeMaxOnline = (e: any) => {
        const value = e.target.value;
        setMaxOnline(value);
    };

    const onSubmitFormRequest = (data: CreateFlightRequestTypes) => {
        const invalidSectors = flightForm.sectorPrices.filter(
            (item: any) => item.numberOfSeats < 0
        );

        if (invalidSectors.length) {
            const invalidSectorsIds = invalidSectors.map((item: any) => item.id);
            setSectorsInvalidNumberOfSeatsIds(invalidSectorsIds);
            dispatch(warningMsg("Keni gabime në mbushjen e të dhënave"));
            return;
        }

        const departureDate = flightForm.dateOfDeparture;
        const departureTime = flightForm.timeOfDeparture;
        const arrivalTime = flightForm.timeOfArrival;

        const departureDateTime = new Date(`${departureDate.split('/').reverse().join('-')}T${departureTime}:00`);
        let arrivalDateTime = new Date(`${departureDate.split('/').reverse().join('-')}T${arrivalTime}:00`);

        if (arrivalDateTime.getTime() === departureDateTime.getTime()) {
            dispatch(errorMsg("Departure time cannot be equal as arrival time!"));
            return;
        }

        if (arrivalDateTime.getTime() < departureDateTime.getTime()) {
            arrivalDateTime.setDate(arrivalDateTime.getDate() + 1);
        }

        if (arrivalDateTime.getTime() <= departureDateTime.getTime()) {
            dispatch(errorMsg("Departure time cannot be greater than arrival time!"));
            return;
        }

        setSectorsInvalidNumberOfSeatsIds([]);
        dispatch(
            createFlight({
                body: {
                    ...flightForm,
                    maxOnline: maxOnline
                },
                onSuccess: () => dispatch(resetTheFlightForm()),
            })
        );
    };

    useEffect(() => {
        if (flightForm.publicAvailability === undefined) {
            dispatch(
                setTheFlightForm({
                    ...flightForm,
                    publicAvailability: true
                })
            );
        }
    })

    const handleChange = (e: any) => {
        dispatch(
            setTheFlightForm({
                ...flightForm,
                maxOnline: maxOnline,
                [e.target.name]: e.target.value,
            })
        );
    };

    useEffect(() => {
        if (currencies.length > 0) {
            const defaultCurrency: any = currencies?.find(
                (item: any) => item?.isoCode === "EUR"
            );

            dispatch(
                setTheFlightForm({
                    ...flightForm,
                    maxOnline: maxOnline,
                    currencyId: defaultCurrency?.id,
                })
            );
        }
    }, [currencies]);

    const availability = [
        {value: true, viewValue: "PUBLIC"},
        {value: false, viewValue: "PRIVATE"},
    ];

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader}>
                <h1 style={{color: "#0D3B66"}}>{t("app.main.flights.create.title", "Register flight")}:</h1>
            </div>
            <Formik
                initialValues={flightForm}
                validationSchema={validationSchema}
                onSubmit={onSubmitFormRequest}
            >
                {({values, errors, setValues}) => (
                    <Form>
                        <div className={classes.row}>
                            <Field
                                variant="outlined"
                                onChange={(e: any) => {
                                    dispatch(
                                        setTheFlightForm({
                                            ...flightForm,
                                            departureAirportId: e.target.value,
                                            arrivalAirportId:
                                                e.target.value === flightForm.arrivalAirportId
                                                    ? ""
                                                    : flightForm.arrivalAirportId,
                                        })
                                    );
                                }}
                                value={flightForm.departureAirportId}
                                name="departureAirportId"
                                label={t("app.main.flights.create.form.field1", "Flight from the airport")}
                                select
                                as={TextField}
                                required
                            >
                                {airports &&
                                    airports.map((b: Airport, index: number) => (
                                        <MenuItem key={b.id + index} id={b.id} value={b.id}>
                                            {b.name} ({b.code})
                                        </MenuItem>
                                    ))}
                            </Field>
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.arrivalAirportId}
                                name="arrivalAirportId"
                                label={t("app.main.schedules.addPassenger.towardsTheAirport", "Drejt aeroportit")}
                                select
                                as={TextField}
                                required
                            >
                                {airports &&
                                    airports.map((c: Airport, index: number) => {
                                        if (c.id !== flightForm.departureAirportId) {
                                            return (
                                                <MenuItem key={c.id + index} id={c.id} value={c.id}>
                                                    {c.name} ({c.code})
                                                </MenuItem>
                                            );
                                        }
                                    })}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="baseline"
                                >
                                    <DatePicker
                                        inputVariant="outlined"
                                        disableToolbar
                                        autoOk
                                        required
                                        variant="inline"
                                        className={classes.textField}
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label={t("app.main.flights.create.form.field2", "Flight time")}
                                        value={DateUtils.toISODate(
                                            flightForm.dateOfDeparture.toString()
                                        )}
                                        disablePast
                                        onChange={(date) => {
                                            if (date)
                                                handleChange({
                                                    target: {
                                                        name: "dateOfDeparture",
                                                        value: DateUtils.convertDate(date.toISOString()),
                                                    },
                                                });
                                        }}
                                    />
                                    <TimePicker
                                        inputVariant="outlined"
                                        format={"HH:mm"}
                                        orientation={"landscape"}
                                        views={["hours", "minutes"]}
                                        ampm={false}
                                        margin="normal"
                                        label={t("app.main.flights.create.form.field3", "Departure time")}
                                        // variant="outlined"
                                        required
                                        name="timeOfDeparture"
                                        value={DateUtils.toDateObject(
                                            flightForm.dateOfDeparture,
                                            flightForm.timeOfDeparture
                                        )}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(e) => {
                                            const date = new Date();
                                            const nowHour = date.getHours();
                                            const nowMin = date.getMinutes();

                                            const pickedHour = ("0" + e?.getHours()).substr(-2);
                                            const pickedMin = ("0" + e?.getMinutes()).substr(-2);

                                            const isSameDay = DateUtils.isEqualDate(
                                                new Date(`${flightForm.dateOfDeparture}`)
                                            );

                                            if (isSameDay) {
                                                if (Number(pickedHour) < Number(nowHour)) {
                                                    handleChange({
                                                        target: {
                                                            name: "timeOfDeparture",
                                                            value: `${nowHour}:${nowMin}`,
                                                        },
                                                    });
                                                } else if (Number(pickedHour) === Number(nowHour)) {
                                                    if (Number(pickedMin) < Number(nowMin)) {
                                                        handleChange({
                                                            target: {
                                                                name: "timeOfDeparture",
                                                                value: `${nowHour}:${nowMin}`,
                                                            },
                                                        });
                                                    } else {
                                                        handleChange({
                                                            target: {
                                                                name: "timeOfDeparture",
                                                                value: `${pickedHour}:${pickedMin}`,
                                                            },
                                                        });
                                                    }
                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: "timeOfDeparture",
                                                            value: `${pickedHour}:${pickedMin}`,
                                                        },
                                                    });
                                                }
                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: "timeOfDeparture",
                                                        value: `${pickedHour}:${pickedMin}`,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                    <TimePicker
                                        disableToolbar
                                        ampm={false}
                                        format="HH:mm"
                                        orientation={"landscape"}
                                        inputVariant="outlined"
                                        label={t("app.main.flights.create.form.field4", "Arrival time")}
                                        // variant="outlined"
                                        autoComplete="true"
                                        name="timeOfArrival"
                                        required
                                        value={DateUtils.toDateObject(
                                            flightForm.dateOfDeparture,
                                            flightForm.timeOfArrival
                                        )}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            const pickedHour = ("0" + e?.getHours()).substr(-2);
                                            const pickedMin = ("0" + e?.getMinutes()).substr(-2);
                                            handleChange({
                                                target: {
                                                    name: "timeOfArrival",
                                                    value: `${pickedHour}:${pickedMin}`,
                                                },
                                            });
                                        }}
                                    />
                                    <Field
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={flightForm.publicAvailability !== undefined ? flightForm.publicAvailability : true}
                                        name="publicAvailability"
                                        label={t("app.main.flights.create.form.field6", "Availability")}
                                        className={classes.textField}
                                        style={{marginRight: 0}}
                                        select
                                        as={TextField}
                                    >
                                        {availability.map((a: any) => {
                                            return <MenuItem value={a.value}>{a.viewValue}</MenuItem>;
                                        })}
                                    </Field>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className={classes.row}>
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.recurringFrequencyInDays}
                                name="recurringFrequencyInDays"
                                label={t("app.main.flights.create.form.field7", "Repeat the flight every")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="start">{t("app.main.flights.create.form.field8", "Day")}</InputAdornment>
                                    ),
                                }}
                                as={TextField}
                            />

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    inputVariant="outlined"
                                    minDate={DateUtils.checkMinDateAtFlight(
                                        flightForm.dateOfDeparture,
                                        flightForm.timeOfDeparture
                                    )}
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    label={t("app.main.flights.create.form.field9", "Until the date")}
                                    value={DateUtils.toISODate(
                                        flightForm.recurringEndDate.toString()
                                    )}
                                    disablePast
                                    onChange={(date) => {
                                        if (date)
                                            handleChange({
                                                target: {
                                                    name: "recurringEndDate",
                                                    value: DateUtils.convertDate(date.toISOString()),
                                                },
                                            });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className={classes.row}>
                            <Field
                                name="numberOfSeats"
                                render={({field, form}: any) => (
                                    <TextField
                                        type="text"
                                        label={t("app.main.flights.create.form.field10", "Number of seats")}
                                        variant="outlined"
                                        name={"numberOfSeats"}
                                        value={flightForm.numberOfSeats}
                                        error={Boolean(form.errors.numberOfSeats)}
                                        required
                                        onChange={(e: any) => {
                                            const numberOfSeatsValue = e.target.value;
                                            setValues((values) => ({
                                                ...values,
                                                numberOfSeats: numberOfSeatsValue,
                                                maxOnline: numberOfSeatsValue,
                                            }));
                                            setMaxOnline(numberOfSeatsValue); // Update maxOnline as well
                                            handleChange(e);
                                        }}
                                        helperText={form.errors.numberOfSeats}
                                    />
                                )}
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.gepack}
                                name="gepack"
                                label="Gepack"
                                as={TextField}
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.gepackINF}
                                name="gepackINF"
                                label="Gepack INF"
                                as={TextField}
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                name="maxOnline"
                                render={({field, form}: any) => (
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={maxOnline}
                                        error={Boolean(form.errors.maxOnline)}
                                        onChange={handleChangeMaxOnline}
                                        helperText={form.errors.maxOnline}
                                        name={"maxOnline"}
                                        label={t("app.main.flights.create.form.field11", "Booking Engine Allotment")}
                                    />
                                )}
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.increasePriceOfOnlineBookingFor}
                                name="increasePriceOfOnlineBookingFor"
                                label={t("app.main.flights.create.form.field12", "Increase Price Of Online Booking For")}
                                as={TextField}
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.airlineCompanyId}
                                name="airlineCompanyId"
                                label={t("app.main.flights.create.form.field13", "Flight operator")}
                                select
                                as={TextField}
                                required
                            >
                                {airlineCompanies &&
                                    airlineCompanies.map((a: AirlineCompany, index: number) => (
                                        <MenuItem key={a.id + index} id={a.id} value={a.id}>
                                            {a.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <Field
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.taxOfFlight}
                                name="taxOfFlight"
                                label={t("app.main.flights.create.form.field14", "Taxes: (EUR)")}
                                as={TextField}
                                InputProps={{inputProps: {min: 0}}}
                                required
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.number.toUpperCase()}
                                name="number"
                                label={t("app.main.flights.create.form.field15", "Flight number")}
                                as={TextField}
                                required
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                onChange={handleChange}
                                variant="outlined"
                                value={flightForm.currencyId}
                                name="currencyId"
                                required
                                label={t("app.main.flights.create.form.field16", "Currency")}
                                select
                                style={{minWidth: 120}}
                                as={TextField}
                            >
                                {currencies && currencies.filter(cr => ['EUR', 'CHF'].includes(cr.isoCode)).map((cr: any, idx) => (
                                    <MenuItem key={idx} value={cr.id}>{cr.name} - {cr.isoCode}</MenuItem>
                                ))}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <FlightSectorsTable
                                sectorsInvalidNumberOfSeatsIds={sectorsInvalidNumberOfSeatsIds}
                            />
                        </div>
                        <div className={classes.row}>
                            <FareFlightTable/>
                        </div>
                        <div className={classes.row}>
                            <LoadingButton
                                type="submit"
                                btnTitle={t("app.main.schedules.update.form.submitButton", "Submit")}
                                isLoading={createFlightsState.loading}
                            />
                            <Button
                                onClick={(e) => {
                                    dispatch(toggleQuickPanel());
                                    history.push("/flights/flights-list");
                                    dispatch(resetTheFlightForm());
                                }}
                            >
                                {t("app.main.schedules.splitPassenger.closeButton", "Close")}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateFlightForm;
