import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {Field} from "formik";
import {TextField,} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {setTheSectorPrice,} from "../store/flights-list/flight-form.slice";
import {AppState} from "app/store";
import {useTranslation} from "react-i18next";

const PricesCurrencyItem = ({price, sectorId, disabledRemoveBtn}: any) => {
  const dispatch = useDispatch();
  const {id, oneWayPrices, returnPrices} = price;
  const flightForm = useSelector(
      (state: AppState) => state.flights.flightsList.flightForm
  );
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    if (e.target.name === "oneWayForAdults" && +e.target.value >= 20) {
      dispatch(
          setTheSectorPrice({
            sectorId,
            pricePayload: {
              name: "returnForAdults",
              value: e.target.value - 10,
            },
          })
      );
      dispatch(
          setTheSectorPrice({
            sectorId,
            pricePayload: {
              name: "oneWayForChildren",
              value: e.target.value - 10,
            },
          })
      );
      dispatch(
          setTheSectorPrice({
            sectorId,
            pricePayload: {
              name: "returnForChildren",
              value: e.target.value - 20,
            },
          })
      );
    }
    dispatch(
        setTheSectorPrice({
          sectorId,
          pricePayload: {
            name: e.target.name,
            value: e.target.value,
          },
        })
    );
    dispatch(
        setTheSectorPrice({
          sectorId,
          pricePayload: {
            name: "oneWayForInfant",
            value: 50,
          },
        })
    );
    dispatch(
        setTheSectorPrice({
          sectorId,
          pricePayload: {
            name: "returnForInfant",
            value: 40,
          },
        })
    );
  };

  const handleChangeForInfants = (e: any) => {
    dispatch(
        setTheSectorPrice({
          sectorId,
          pricePayload: {
            name: e.target.name,
            value: e.target.value,
          },
        })
    );
  }

  return (
      <TableRow>
        <TableCell style={{borderBottom: "none"}}>
          <Field
              type="number"
              onChange={handleChange}
              name="oneWayForAdults"
              label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
              variant="outlined"
              size="small"
              value={oneWayPrices.forAdults}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
          <Field
              type="number"
              onChange={handleChange}
              name="returnForAdults"
              label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
              variant="outlined"
              size="small"
              value={returnPrices.forAdults}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
        </TableCell>
        <TableCell style={{borderBottom: "none"}}>
          <Field
              type="number"
              onChange={handleChange}
              name="oneWayForChildren"
              label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
              variant="outlined"
              size="small"
              value={oneWayPrices.forChildren}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
          <Field
              type="number"
              onChange={handleChange}
              name="returnForChildren"
              label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
              variant="outlined"
              size="small"
              value={returnPrices.forChildren}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
        </TableCell>
        <TableCell style={{borderBottom: "none"}}>
          <Field
              type="number"
              onChange={handleChangeForInfants}
              name="oneWayForInfant"
              label={t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}
              variant="outlined"
              size="small"
              value={oneWayPrices.forInfants}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
          <Field
              type="number"
              onChange={handleChangeForInfants}
              name="returnForInfant"
              label={t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}
              variant="outlined"
              size="small"
              value={returnPrices.forInfants}
              style={{margin: "8px"}}
              as={TextField}
              InputProps={{inputProps: {min: 0}}}
              required
          />
        </TableCell>
      </TableRow>
  );
};

export default PricesCurrencyItem;
