import React, {useEffect, useMemo, useState} from "react";
import {MenuItem, Paper, TableBody, TableContainer, TextField,} from "@material-ui/core";
import {Field} from "formik";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {PricesByAgeCategoryTypes} from "../../../types/service-types/SectorPricesTypes";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateUtils from "../../../utils/DateUtils";
import {WAY_TYPE} from "../../../constants/constants";
import {setScheduleTravellers} from "../store/create-schedule.slice";
import FlightPricesComponent from "../schedule-results/FlightPricesComponent";
import {useTranslation} from "react-i18next";
import useAuthority from "../../../hooks/useAuthority";
import {ROLES} from "../../../constants/roles";

const ScheduleFinalTable = ({isSubmitting, setFieldValue, setSubmitting}: any) => {
    const dispatch = useDispatch();
    const {wayType} = useSelector((state: AppState) => state.schedule.scheduleForm);
    const {scheduleForm} = useSelector((state: AppState) => state.schedule.scheduleForm);
    const {
        selectedFlight,
        selectedReturnFlight
    } = useSelector((state: AppState) => state.schedule.flightResults) as any;
    const {t} = useTranslation();
    const travellers = useSelector((state: AppState) => state.schedule.createSchedule.travellers);
    const {hasUserAnyAuthority} = useAuthority();
    const departureFlightPrices = selectedFlight?.availableSectorPrice?.price;
    const returningFlightPrices = wayType === WAY_TYPE.RETURN_FLIGHT ? selectedReturnFlight?.availableSectorPrice?.price : null;
    const depTaxFlight = selectedFlight?.taxOfFlight || 0;
    const retTaxFlight = wayType === WAY_TYPE.RETURN_FLIGHT ? selectedFlight?.taxOfFlight + selectedReturnFlight?.taxOfFlight : 0;
    const totalOfTax = !selectedReturnFlight ? depTaxFlight : retTaxFlight;
    const [nameErrors, setNameErrors] = useState<{ [key: string]: string }>({});
    const [lastnameErrors, setLastnameErrors] = useState<{ [key: string]: string }>({});
    const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});

    const handleChange = (e: any, key: string) => {
        // @ts-ignore
        const tr = {...travellers[key]};

        const value = e.target.value;

        if (Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value)) {
            dispatch(setScheduleTravellers({
                ...travellers, [key]: {
                    ...tr, [e.target.name]: value,
                },
            }));
        } else {
            dispatch(setScheduleTravellers({
                ...travellers, [key]: {
                    ...tr, [e.target.name]: value.toUpperCase(),
                },
            }));
        }
    };

    useEffect(() => {
        if (isSubmitting) {
            setFieldValue("travellers", {...travellers});
            setSubmitting(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        const initialTravellers = {
            ...getAdultInitValues(), ...getChildInitValues(), ...getBabyInitValues(),
        };

        const isEmpty = Object.keys(travellers).length === 0;
        if (isEmpty || Object.keys(initialTravellers).length !== Object.keys(travellers).length || Object.keys(initialTravellers).some((t, i) => t !== Object.keys(travellers)[i])) {
            dispatch(setScheduleTravellers(initialTravellers));
        } else {
            const newTravellers = Object.keys(travellers).map((key: any, idx: number) => {
                // @ts-ignore
                const obj = travellers[key];
                // @ts-ignore
                const objKeys = Object.keys(travellers[key]);
                const type = objKeys[0];
                const departureFlightPriceKey = objKeys[6];
                const returningFlightPriceKey = objKeys[7];
                const {departureFlightPrice, returningFlightPrice} = getDepartureAndReturningPrice(obj[type]);

                return {
                    ...obj,
                    [departureFlightPriceKey]: departureFlightPrice,
                    [returningFlightPriceKey]: returningFlightPrice,
                };
            });

            dispatch(setScheduleTravellers(newTravellers));
        }
    }, []);

    const getMinBirthDate = (ageCategory: string) => {
        switch (ageCategory) {
            case "ADULT":
                return new Date("1900-01-01");
            case "CHILD":
                return DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 1);
            case "INF":
                return DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(2), 1);
            default:
                return new Date("1900-01-01");
        }
    };

    const getMaxBirthDate = (ageCategory: string) => {
        switch (ageCategory) {
            case "ADULT":
                return DateUtils.getDateByYearsAgo(13);
            case "CHILD":
                return DateUtils.getDateByYearsAgo(2);
            case "INF":
                return new Date();
            default:
                return new Date();
        }
    };

    const isNumeric = (str: string): boolean => {
        return (!isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str))); // ...and ensure strings of whitespace fail
    };

    const getPriceByAgeCategory = (ageCategory: string, prices: PricesByAgeCategoryTypes): number | null => {
        switch (ageCategory) {
            case "ADULT":
                return prices?.forAdults;
            case "CHILD":
                return prices?.forChildren;
            case "INF":
                return prices?.forInfants;
            default:
                return null;
        }
    };

    const getDepartureAndReturningPrice = (type: string) => {
        let departureFlightPrice = null;
        let returningFlightPrice = null;

        if (wayType === WAY_TYPE.ONE_WAY) {
            if (departureFlightPrices?.oneWayPrices) {
                departureFlightPrice = getPriceByAgeCategory(type, departureFlightPrices?.oneWayPrices);
            }
        } else {
            if (departureFlightPrices?.returnPrices) {
                departureFlightPrice = getPriceByAgeCategory(type, departureFlightPrices?.returnPrices);
            }

            if (returningFlightPrices?.returnPrices) {
                returningFlightPrice = getPriceByAgeCategory(type, returningFlightPrices?.returnPrices);
            }
        }
        return {
            departureFlightPrice, returningFlightPrice,
        };
    };
    const getRowObject = (type: string, idx: number) => {
        const {departureFlightPrice, returningFlightPrice} = getDepartureAndReturningPrice(type) as any;

        return {
            [type + "_ageCategory_" + idx]: type,
            [type + "_gender_" + idx]: "MALE",
            [type + "_name_" + idx]: fieldValues[type + "_name_" + idx],
            [type + "_surname_" + idx]: fieldValues[type + "_surname_" + idx],
            [type + "_dateOfBirth_" + idx]: getMaxBirthDate(type),
            [type + "_nationality_" + idx]: fieldValues[type + "_nationality_" + idx],
            [type + "_departureFlightPrice_" + idx]: +departureFlightPrice,
            [type + "_returningFlightPrice_" + idx]: wayType === WAY_TYPE.RETURN_FLIGHT ? +returningFlightPrice : null,
            [type + "_departureTaxFlight_" + idx]: selectedFlight?.taxOfFlight,
            [type + "_returnTaxFlight_" + idx]: wayType === WAY_TYPE.RETURN_FLIGHT ? selectedReturnFlight?.taxOfFlight : null,
        };
    };

    const getAdultInitValues = () => {
        let combinedObj: any = {};
        Array.from(new Array(scheduleForm.adult))
            .map((_, idx) => getRowObject("ADULT", idx))
            .forEach((obj: any) => {
                const objKeys: string[] = Object.keys(obj);
                combinedObj[objKeys[2]] = obj;
            });
        return combinedObj;
    };

    const getChildInitValues = () => {
        let combinedObj: any = {};
        Array.from(new Array(scheduleForm.children))
            .map((_, idx) => getRowObject("CHILD", idx))
            .forEach((obj: any) => {
                const objKeys: string[] = Object.keys(obj);
                combinedObj[objKeys[2]] = obj;
            });

        return combinedObj;
    };

    const getBabyInitValues = () => {
        let combinedObj: any = {};
        Array.from(new Array(scheduleForm.infants))
            .map((_, idx) => getRowObject("INF", idx))
            .forEach((obj: any) => {
                const objKeys: string[] = Object.keys(obj);
                combinedObj[objKeys[2]] = obj;
            });

        return combinedObj;
    };

    const validatePrice = (e: any) => {
        if (e.target.value === "") {
            e.target.setCustomValidity(t("app.main.schedules.module.finalSchedules.outgoingPrice", "Please write price!"));
        } else if (e.target.validity.patternMismatch) {
            e.target.setCustomValidity("Çmimi duhet të jetë numër");
        } else {
            e.target.setCustomValidity("");
        }

        return true;
    };

    const regexForOnlyLetters = useMemo(() => {
        return new RegExp("^[A-Za-z]+$");
    }, [])


    const validateName = (e: any, key: string) => {
        const newErrors = {...nameErrors};
        newErrors[key] = '';

        if (!e.target.value) {
            newErrors[key] = 'Please enter the required field';
        } else if (!regexForOnlyLetters.test(e.target.value)) {
            newErrors[key] = 'Only alphabets are allowed';
        }
        setNameErrors(newErrors);
    };

    const validateLastname = (e: any, key: string) => {
        const newErrors = {...lastnameErrors};
        newErrors[key] = '';

        if (!e.target.value) {
            newErrors[key] = 'Please enter the required field';
        } else if (!regexForOnlyLetters.test(e.target.value)) {
            newErrors[key] = 'Only alphabets are allowed';
        }
        setLastnameErrors(newErrors);
    };

    return (
        <>
            <div style={{marginBottom: "15px", textAlign: "right"}}/>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: "bold"}}>#</TableCell>
                            <TableCell
                                style={{fontWeight: "bold"}}>{t("app.main.schedules.updateTravelerFlight.age", "Age")}</TableCell>
                            <TableCell
                                style={{fontWeight: "bold"}}>{t("app.main.schedules.updateTravelerFlight.gender", "Gender")}</TableCell>
                            <TableCell
                                style={{fontWeight: "bold"}}>{t("app.main.schedules.searchPanel.input.status", "Status")}</TableCell>
                            <TableCell style={{fontWeight: "bold"}}>
                                {t("app.main.schedules.addPassenger.passengerName", "Passenger name")}
                            </TableCell>
                            <TableCell style={{fontWeight: "bold"}}>
                                {t("app.main.schedules.addPassenger.passengerLastname", "Passenger lastname")}
                            </TableCell>
                            <TableCell
                                style={{fontWeight: "bold"}}>{t("app.main.schedules.addPassenger.passengerBirthday", "Date of birth")}</TableCell>
                            <TableCell
                                style={{fontWeight: "bold"}}>{t("app.main.schedule.scheduleForm.thirdStep.outgoingPrice", "Outgoing Price")}</TableCell>
                            {wayType === WAY_TYPE.RETURN_FLIGHT && (<TableCell style={{fontWeight: "bold"}}>
                                {t("app.main.schedule.scheduleForm.thirdStep.returnPrice", "Return Price")}
                            </TableCell>)}
                            <TableCell style={{fontWeight: "bold"}}>
                                {t("app.main.schedule.scheduleForm.thirdStep.taxPrice", "Price of tax")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {travellers && Object.keys(travellers).map((key: any, idx: number) => {
                            // @ts-ignore
                            const obj = travellers[key];
                            // @ts-ignore
                            const objKeys = Object.keys(travellers[key]);
                            const statusKey = objKeys[0];
                            const genderKey = objKeys[1];
                            const nameKey = objKeys[2];
                            const surnameKey = objKeys[3];
                            const dateOfBirthKey = objKeys[4];
                            const departureFlightPriceKey = objKeys[6];
                            const returningFlightPriceKey = objKeys[7];
                            const departureFlightTaxKey = objKeys[8];
                            const returningFlightTaxKey = objKeys[9];
                            const returnFlightTax = obj[returningFlightTaxKey] ? obj[returningFlightTaxKey] : 0;
                            // @ts-ignore
                            return (
                                <TableRow key={idx + nameKey}>
                                    <TableCell>
                                        <div style={{margin: ".5rem 0"}}>{idx + 1}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{margin: ".5rem 0"}}>
                                            <strong style={{color: "rgba(0,0,0,.4)"}}>
                                                {obj[statusKey]}
                                            </strong>
                                        </div>
                                    </TableCell>
                                    {obj[statusKey] === "ADULT" && <>
                                        <TableCell>
                                            <Field
                                                onChange={(e: any) => handleChange(e, key)}
                                                variant="outlined"
                                                size="small"
                                                name={genderKey}
                                                required
                                                value={obj[genderKey]}
                                                select
                                                as={TextField}
                                            >
                                                <MenuItem
                                                    value="MALE">{t("app.main.schedules.addPassenger.male", "Male")}</MenuItem>
                                                <MenuItem
                                                    value="FEMALE">{t("app.main.schedules.addPassenger.female", "Female")}</MenuItem>
                                            </Field>
                                        </TableCell>
                                    </>}

                                    {(obj[statusKey] === "CHILD" || obj[statusKey] === "INF") && <>
                                        <TableCell>
                                        </TableCell>
                                    </>}

                                    {obj[statusKey] === "ADULT" && obj[genderKey] === "MALE" && <>
                                        <TableCell>
                                            <div style={{margin: ".5rem 0"}}>
                                                <strong style={{color: "rgba(0,0,0,.4)"}}>
                                                    MR
                                                </strong>
                                            </div>
                                        </TableCell>
                                    </>}

                                    {obj[statusKey] === "ADULT" && obj[genderKey] === "FEMALE" && <>
                                        <TableCell>
                                            <div style={{margin: ".5rem 0"}}>
                                                <strong style={{color: "rgba(0,0,0,.4)"}}>
                                                    MRS
                                                </strong>
                                            </div>
                                        </TableCell>
                                    </>}

                                    {(obj[statusKey] === "CHILD" || obj[statusKey] === "INF") && <>
                                        <TableCell>
                                        </TableCell>
                                    </>}

                                    <TableCell>
                                        <Field
                                            onChange={(e: any) => {
                                                handleChange(e, key);
                                                validateName(e, key);
                                            }}
                                            variant="outlined"
                                            size="small"
                                            name={nameKey}
                                            value={obj[nameKey]}
                                            placeholder={t("app.main.schedules.addPassenger.passengerName", "Passenger name")}
                                            as={TextField}
                                            required
                                        />
                                        {nameErrors.hasOwnProperty(key) && (<span
                                            style={{color: 'red', display: 'inline-block'}}>{nameErrors[key]}</span>)}
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            onChange={(e: any) => {
                                                handleChange(e, key);
                                                validateLastname(e, key);
                                            }}
                                            variant="outlined"
                                            size="small"
                                            name={surnameKey}
                                            value={obj[surnameKey]}
                                            placeholder={t("app.main.schedules.addPassenger.passengerLastname", "Passenger lastname")}
                                            as={TextField}
                                            required
                                        />
                                        {lastnameErrors.hasOwnProperty(key) && (<span
                                            style={{
                                                color: 'red',
                                                display: 'inline-block'
                                            }}>{lastnameErrors[key]}</span>)}
                                    </TableCell>
                                    {obj[statusKey] === "ADULT" && <>
                                        <TableCell>
                                        </TableCell>
                                    </>}
                                    {(obj[statusKey] === "CHILD" || obj[statusKey] === "INF") && <>
                                        <TableCell>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    style={{margin: 0}}
                                                    minDate={getMinBirthDate(obj[statusKey])}
                                                    maxDate={getMaxBirthDate(obj[statusKey])}
                                                    disableToolbar
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    size="small"
                                                    label={t("app.main.schedules.addPassenger.passengerBirthday", "Date of birth")}
                                                    id="date-picker-inline"
                                                    value={obj[dateOfBirthKey]}
                                                    onChange={(date) => {
                                                        handleChange({
                                                            target: {
                                                                name: dateOfBirthKey, value: date,
                                                            },
                                                        }, key);

                                                    }}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                    </>}

                                    <TableCell>
                                        <Field
                                            onChange={(e: any) => {
                                                validatePrice(e);
                                                handleChange(e, key);
                                            }}
                                            variant="outlined"
                                            size="small"
                                            name={departureFlightPriceKey}
                                            value={obj[departureFlightPriceKey]}
                                            placeholder={t("app.main.schedule.scheduleForm.thirdStep.outgoingPrice", "Outgoing Price")}
                                            as={TextField}
                                            required
                                            inputProps={{pattern: "[0-9]*"}}
                                            disabled={hasUserAnyAuthority([ROLES.ROLE_AGENT])}
                                        />
                                    </TableCell>
                                    {wayType === WAY_TYPE.RETURN_FLIGHT && (<TableCell>
                                        <Field
                                            onChange={(e: any) => {
                                                validatePrice(e);
                                                handleChange(e, key);
                                            }}
                                            variant="outlined"
                                            size="small"
                                            name={returningFlightPriceKey}
                                            value={obj[returningFlightPriceKey]}
                                            placeholder={t("app.main.schedule.scheduleForm.thirdStep.returnPrice", "Return Price")}
                                            as={TextField}
                                            required
                                            inputProps={{pattern: "[0-9]*"}}
                                            disabled={hasUserAnyAuthority([ROLES.ROLE_AGENT])}
                                        />
                                    </TableCell>)}
                                    <TableCell>
                                        <Field
                                            variant="outlined"
                                            size="small"
                                            value={totalOfTax}
                                            name="Çmimi i taksës"
                                            placeholder={t("app.main.schedule.scheduleForm.thirdStep.taxPrice", "Price of tax")}
                                            as={TextField}
                                            style={{backgroundColor: "#E5E5E5"}}
                                            inputProps={{pattern: "[0-9]*"}}
                                            disabled={true}
                                        />
                                    </TableCell>
                                </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div
                style={{
                    display: "flex", flex: 1, justifyContent: "flex-end", padding: "15px", background: "#e7e7e7",
                }}
            >
                <FlightPricesComponent
                    typeOfFlight={wayType === "0" ? "ONE_WAY" : "RETURN_WAY"}
                />
            </div>
        </>);
};

export default ScheduleFinalTable;
