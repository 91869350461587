import React, {useEffect} from "react";
import {Paper, TableBody, TableContainer, TextField} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Field} from "formik";
import {setFlightForm, setTheFlightForm,} from "../store/flights-list/flight-form.slice";
import {flightsFormStyles} from "../flights.styles";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "app/store";
import {useTranslation} from "react-i18next";

export const FareFlightTable = () => {
  const classes = flightsFormStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const flightForm = useSelector(
      (state: AppState) => state.flights.flightsList.flightForm
  );
  const {sectorPrices} = useSelector(
      (state: AppState) => state.flights.flightsList.flightForm
  );

  const autoPopulatePrices = (oneWayPrices: any, returnPrices: any) => {
    dispatch(
        setTheFlightForm({
          ...flightForm,
          economyWithRestrictions: {
            ...flightForm.economyWithRestrictions,
            noOfSeats: flightForm.numberOfSeats,

            fareOneWay: {
              ...flightForm.economyWithRestrictions.fareOneWay,
              amount: +oneWayPrices?.forAdults,
              luggage: "1~20~0.00",
            },
            fareDefault: {
              ...flightForm.economyWithRestrictions.fareDefault,
              amount: +oneWayPrices?.forAdults,
              luggage: "1~20~0.00",
            },
            fareReturn: {
              ...flightForm.economyWithRestrictions.fareReturn,
              amount: +returnPrices?.forAdults,
              luggage: "1~20~0.00",
            },
          },
          // economyWithoutRestrictions: {
          //   ...flightForm.economyWithoutRestrictions,
          //   noOfSeats: 15,
          //   fareOneWay: {
          //     ...flightForm.economyWithoutRestrictions.fareOneWay,
          //     amount: +oneWayPrices?.forAdults + 10,
          //     luggage: "1~25~0.00",
          //   },
          //   fareDefault: {
          //     ...flightForm.economyWithoutRestrictions.fareDefault,
          //     amount: +oneWayPrices?.forAdults + 10,
          //     luggage: "1~25~0.00",
          //   },
          //   fareReturn: {
          //     ...flightForm.economyWithoutRestrictions.fareReturn,
          //     amount: +returnPrices?.forAdults + 10,
          //     luggage: "1~25~0.00",
          //   },
          // },
          // economyPremium: {
          //   ...flightForm.economyPremium,
          //   noOfSeats: 15,
          //   fareOneWay: {
          //     ...flightForm.economyPremium.fareOneWay,
          //     amount: +oneWayPrices?.forAdults + 20,
          //     luggage: "1~30~0.00",
          //   },
          //   fareDefault: {
          //     ...flightForm.economyPremium.fareDefault,
          //     amount: +oneWayPrices?.forAdults + 20,
          //     luggage: "1~30~0.00",
          //   },
          //   fareReturn: {
          //     ...flightForm.economyPremium.fareReturn,
          //     amount: +returnPrices?.forAdults + 20,
          //     luggage: "1~30~0.00",
          //   },
          // },
        })
    );
  };

  useEffect(() => {
    setFlightForm({
      ...flightForm,
      economyWithRestrictions: {
        ...flightForm.economyWithRestrictions,
        noOfSeats: flightForm.numberOfSeats,
      },
      // economyPremium: {
      //   ...flightForm.economyPremium,
      //   noOfSeats: 15,
      // },
      // economyWithoutRestrictions: {
      //   ...flightForm.economyWithoutRestrictions,
      //   noOfSeats: 15,
      // },
    });
  }, []);

  useEffect(() => {
    const {oneWayPrices, returnPrices} = sectorPrices[0].prices;
    autoPopulatePrices(oneWayPrices, returnPrices);
  }, [sectorPrices[0].prices]);

  const handleChange = (e: any) => {
    dispatch(
        setTheFlightForm({
          ...flightForm,
          [e.target.name]: e.target.value,
        })
    );
  };

  return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight: "bold"}}>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow1", "Seat class")}</TableCell>
              {/*<TableCell style={{fontWeight: 'bold'}}>Emri</TableCell>*/}
              <TableCell style={{fontWeight: "bold"}}>
                {t( "app.main.flights.create.form.field10", "Number of seats")}
              </TableCell>
              <TableCell style={{fontWeight: "bold"}}>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow2", "Fare List")}</TableCell>
              <TableCell style={{fontWeight: "bold"}}></TableCell>
              <TableCell style={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell>
                <div>
                  <div style={{flex: 1}}>
                    <Field
                        type="phone"
                        name="seatClass"
                        value={flightForm.economyWithRestrictions?.seatClass}
                        style={{display: "none"}}
                    />
                    <p>
                      <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow3", "ECONOMY_WITH_RESTRICTIONSE")}</strong>
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div style={{flex: 0.4, textAlign: "right"}}>
                  <Field
                      placeholder={t( "app.main.flights.create.form.field10", "Number of seats")}
                      onChange={(e: any) => {
                        dispatch(
                            setTheFlightForm({
                              ...flightForm,
                              economyWithRestrictions: {
                                ...flightForm.economyWithRestrictions,
                                noOfSeats: e.target.value,
                              },
                            })
                        );
                      }}
                      type="text"
                      variant="outlined"
                      size="small"
                      name="noOfSeats"
                      value={flightForm.economyWithRestrictions?.noOfSeats}
                      required
                      fullWidth
                      as={TextField}
                  />
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Field
                      type="phone"
                      name="fareType"
                      value={
                        flightForm.economyWithRestrictions?.fareDefault.fareType
                      }
                      style={{display: "none"}}
                  />
                  <div style={{display: "flex", marginBottom: "1rem"}}>
                    <p style={{marginRight: "6px"}}>
                      <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow4", "Default")}</strong>
                    </p>
                  </div>
                  <div style={{marginRight: "4rem", marginBottom: "1rem"}}>
                    <Field
                        name="amount"
                        render={() => (
                            <TextField
                                placeholder={t("app.main.schedules.payments.amount", "Amount")}
                                inputProps={{
                                  style: {width: "100%"},
                                }}
                                onChange={(e: any) => {
                                  dispatch(
                                      setTheFlightForm({
                                        ...flightForm,
                                        economyWithRestrictions: {
                                          ...flightForm.economyWithRestrictions,
                                          fareDefault: {
                                            ...flightForm.economyWithRestrictions
                                                .fareDefault,
                                            amount: e.target.value,
                                          },
                                        },
                                      })
                                  );
                                }}
                                type="number"
                                variant="outlined"
                                size="small"
                                name="amount"
                                value={
                                  flightForm.economyWithRestrictions?.fareDefault.amount
                                }
                            />
                        )}
                    />
                  </div>
                  <Field
                      name="luggage"
                      render={() => (
                          <TextField
                              style={{width: "70%"}}
                              placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}
                              onChange={(e: any) => {
                                dispatch(
                                    setTheFlightForm({
                                      ...flightForm,
                                      economyWithRestrictions: {
                                        ...flightForm.economyWithRestrictions,
                                        fareDefault: {
                                          ...flightForm.economyWithRestrictions
                                              .fareDefault,
                                          luggage: e.target.value,
                                        },
                                      },
                                    })
                                );
                              }}
                              inputProps={{
                                style: {width: "100%"},
                              }}
                              type="string"
                              variant="outlined"
                              size="small"
                              name="luggage"
                              value={
                                flightForm.economyWithRestrictions?.fareDefault.luggage
                              }
                          />
                      )}
                  />
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Field
                      type="phone"
                      name="fareType"
                      value={
                        flightForm.economyWithRestrictions?.fareDefault.fareType
                      }
                      style={{display: "none"}}
                  />
                  <div style={{display: "flex", marginBottom: "1rem"}}>
                    <p style={{marginRight: "6px"}}>
                      <strong>{t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}</strong>
                    </p>
                  </div>
                  <div style={{marginRight: "4rem", marginBottom: "1rem"}}>
                    <Field
                        name="amount"
                        render={() => (
                            <TextField
                                placeholder={t("app.main.schedules.payments.amount", "Amount")}
                                inputProps={{
                                  style: {width: "100%"},
                                }}
                                onChange={(e: any) => {
                                  dispatch(
                                      setTheFlightForm({
                                        ...flightForm,
                                        economyWithRestrictions: {
                                          ...flightForm.economyWithRestrictions,
                                          fareOneWay: {
                                            ...flightForm.economyWithRestrictions
                                                .fareOneWay,
                                            amount: e.target.value,
                                          },
                                        },
                                      })
                                  );
                                }}
                                type="number"
                                variant="outlined"
                                size="small"
                                name="amount"
                                value={
                                  flightForm.economyWithRestrictions?.fareOneWay.amount
                                }
                            />
                        )}
                    />
                  </div>
                  <Field
                      name="luggage"
                      render={() => (
                          <TextField
                              placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}
                              style={{width: "70%"}}
                              inputProps={{
                                style: {width: "100%"},
                              }}
                              onChange={(e: any) => {
                                dispatch(
                                    setTheFlightForm({
                                      ...flightForm,
                                      economyWithRestrictions: {
                                        ...flightForm.economyWithRestrictions,
                                        fareOneWay: {
                                          ...flightForm.economyWithRestrictions
                                              .fareOneWay,
                                          luggage: e.target.value,
                                        },
                                      },
                                    })
                                );
                              }}
                              type="string"
                              variant="outlined"
                              size="small"
                              name="luggage"
                              value={
                                flightForm.economyWithRestrictions?.fareOneWay.luggage
                              }
                          />
                      )}
                  />
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Field
                      type="phone"
                      name="fareType"
                      value={
                        flightForm.economyWithRestrictions?.fareReturn.fareType
                      }
                      style={{display: "none"}}
                  />
                  <div style={{display: "flex", marginBottom: "1rem"}}>
                    <p style={{marginRight: "6px"}}>
                      <strong>{t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}</strong>
                    </p>
                  </div>
                  <div style={{marginRight: "4rem", marginBottom: "1rem"}}>
                    <Field
                        name="amount"
                        render={() => (
                            <TextField
                                placeholder={t("app.main.schedules.payments.amount", "Amount")}
                                inputProps={{
                                  style: {width: "100%"},
                                }}
                                onChange={(e: any) => {
                                  dispatch(
                                      setTheFlightForm({
                                        ...flightForm,
                                        economyWithRestrictions: {
                                          ...flightForm.economyWithRestrictions,
                                          fareReturn: {
                                            ...flightForm.economyWithRestrictions
                                                .fareReturn,
                                            amount: e.target.value,
                                          },
                                        },
                                      })
                                  );
                                }}
                                type="number"
                                variant="outlined"
                                size="small"
                                name="amount"
                                value={
                                  flightForm.economyWithRestrictions?.fareReturn.amount
                                }
                            />
                        )}
                    />
                  </div>
                  <Field
                      name="luggage"
                      render={() => (
                          <TextField
                              placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}
                              style={{width: "70%"}}
                              inputProps={{
                                style: {width: "100%"},
                              }}
                              onChange={(e: any) => {
                                dispatch(
                                    setTheFlightForm({
                                      ...flightForm,
                                      economyWithRestrictions: {
                                        ...flightForm.economyWithRestrictions,
                                        fareReturn: {
                                          ...flightForm.economyWithRestrictions
                                              .fareReturn,
                                          luggage: e.target.value,
                                        },
                                      },
                                    })
                                );
                              }}
                              type="string"
                              variant="outlined"
                              size="small"
                              name="luggage"
                              value={
                                flightForm.economyWithRestrictions?.fareReturn.luggage
                              }
                          />
                      )}
                  />
                </div>
              </TableCell>
            </TableRow>
            {/*<TableRow className={classes.tableRow}>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <div style={{flex: 1}}>*/}
            {/*        <Field*/}
            {/*            type="phone"*/}
            {/*            name="seatClass"*/}
            {/*            value={flightForm.economyWithoutRestrictions?.seatClass}*/}
            {/*            style={{display: "none"}}*/}
            {/*        />*/}
            {/*        <p>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow6", "ECONOMY_WITHOUT_RESTRICTIONS")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div style={{flex: 0.4, textAlign: "right"}}>*/}
            {/*      <Field*/}
            {/*          type="text"*/}
            {/*          variant="outlined"*/}
            {/*          size="small"*/}
            {/*          name="noOfSeats"*/}
            {/*          placeholder={t( "app.main.flights.create.form.field10", "Number of seats")}*/}
            {/*          onChange={(e: any) => {*/}
            {/*            dispatch(*/}
            {/*                setTheFlightForm({*/}
            {/*                  ...flightForm,*/}
            {/*                  economyWithoutRestrictions: {*/}
            {/*                    ...flightForm.economyWithoutRestrictions,*/}
            {/*                    noOfSeats: e.target.value,*/}
            {/*                  },*/}
            {/*                })*/}
            {/*            );*/}
            {/*          }}*/}
            {/*          value={flightForm.economyWithoutRestrictions?.noOfSeats}*/}
            {/*          required*/}
            {/*          fullWidth*/}
            {/*          as={TextField}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={*/}
            {/*            flightForm.economyWithoutRestrictions?.fareDefault.fareType*/}
            {/*          }*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow4", "Default")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyWithoutRestrictions: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions,*/}
            {/*                              fareDefault: {*/}
            {/*                                ...flightForm.economyWithoutRestrictions*/}
            {/*                                    .fareDefault,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="text"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={*/}
            {/*                      flightForm.economyWithoutRestrictions?.fareDefault*/}
            {/*                          .amount*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyWithoutRestrictions: {*/}
            {/*                            ...flightForm.economyWithoutRestrictions,*/}
            {/*                            fareDefault: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions*/}
            {/*                                  .fareDefault,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={*/}
            {/*                    flightForm.economyWithoutRestrictions?.fareDefault*/}
            {/*                        .luggage*/}
            {/*                  }*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={*/}
            {/*            flightForm.economyWithoutRestrictions?.fareOneWay.fareType*/}
            {/*          }*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyWithoutRestrictions: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions,*/}
            {/*                              fareOneWay: {*/}
            {/*                                ...flightForm.economyWithoutRestrictions*/}
            {/*                                    .fareOneWay,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="number"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={*/}
            {/*                      flightForm.economyWithoutRestrictions?.fareOneWay*/}
            {/*                          .amount*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyWithoutRestrictions: {*/}
            {/*                            ...flightForm.economyWithoutRestrictions,*/}
            {/*                            fareOneWay: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions*/}
            {/*                                  .fareOneWay,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={*/}
            {/*                    flightForm.economyWithoutRestrictions?.fareOneWay*/}
            {/*                        .luggage*/}
            {/*                  }*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={*/}
            {/*            flightForm.economyWithoutRestrictions?.fareReturn.fareType*/}
            {/*          }*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyWithoutRestrictions: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions,*/}
            {/*                              fareReturn: {*/}
            {/*                                ...flightForm.economyWithoutRestrictions*/}
            {/*                                    .fareReturn,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="number"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={*/}
            {/*                      flightForm.economyWithoutRestrictions?.fareReturn*/}
            {/*                          .amount*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyWithoutRestrictions: {*/}
            {/*                            ...flightForm.economyWithoutRestrictions,*/}
            {/*                            fareReturn: {*/}
            {/*                              ...flightForm.economyWithoutRestrictions*/}
            {/*                                  .fareReturn,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={*/}
            {/*                    flightForm.economyWithoutRestrictions?.fareReturn*/}
            {/*                        .luggage*/}
            {/*                  }*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow className={classes.tableRow}>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <div style={{flex: 1}}>*/}
            {/*        <Field*/}
            {/*            type="phone"*/}
            {/*            name="seatClass"*/}
            {/*            value={flightForm.economyPremium?.seatClass}*/}
            {/*            style={{display: "none"}}*/}
            {/*        />*/}
            {/*        <p>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow7", "ECONOMY_PREM")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div style={{flex: 0.4, textAlign: "right"}}>*/}
            {/*      <Field*/}
            {/*          placeholder={t( "app.main.flights.create.form.field10", "Number of seats")}*/}
            {/*          type="text"*/}
            {/*          variant="outlined"*/}
            {/*          size="small"*/}
            {/*          name="noOfSeats"*/}
            {/*          onChange={(e: any) => {*/}
            {/*            dispatch(*/}
            {/*                setTheFlightForm({*/}
            {/*                  ...flightForm,*/}
            {/*                  economyPremium: {*/}
            {/*                    ...flightForm.economyPremium,*/}
            {/*                    noOfSeats: e.target.value,*/}
            {/*                  },*/}
            {/*                })*/}
            {/*            );*/}
            {/*          }}*/}
            {/*          value={flightForm.economyPremium?.noOfSeats}*/}
            {/*          required*/}
            {/*          fullWidth*/}
            {/*          as={TextField}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={flightForm.economyPremium?.fareDefault.fareType}*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.fareFlightTable.tableRow4", "Default")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyPremium: {*/}
            {/*                              ...flightForm.economyPremium,*/}
            {/*                              fareDefault: {*/}
            {/*                                ...flightForm.economyPremium.fareDefault,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="text"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={flightForm.economyPremium?.fareDefault.amount}*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyPremium: {*/}
            {/*                            ...flightForm.economyPremium,*/}
            {/*                            fareDefault: {*/}
            {/*                              ...flightForm.economyPremium.fareDefault,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={flightForm.economyPremium?.fareDefault.luggage}*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={flightForm.economyPremium?.fareOneWay.fareType}*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.priceTable.ow", "One Way")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyPremium: {*/}
            {/*                              ...flightForm.economyPremium,*/}
            {/*                              fareOneWay: {*/}
            {/*                                ...flightForm.economyPremium.fareOneWay,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="number"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={flightForm.economyPremium?.fareOneWay.amount}*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyPremium: {*/}
            {/*                            ...flightForm.economyPremium,*/}
            {/*                            fareOneWay: {*/}
            {/*                              ...flightForm.economyPremium.fareOneWay,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={flightForm.economyPremium?.fareOneWay.luggage}*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*  <TableCell>*/}
            {/*    <div>*/}
            {/*      <Field*/}
            {/*          type="phone"*/}
            {/*          name="fareType"*/}
            {/*          value={flightForm.economyPremium?.fareReturn.fareType}*/}
            {/*          style={{display: "none"}}*/}
            {/*      />*/}
            {/*      <div style={{display: "flex", marginBottom: "1rem"}}>*/}
            {/*        <p style={{marginRight: "6px"}}>*/}
            {/*          <strong>{t("app.main.flights.flightPriceTable.priceTable.rt", "Return")}</strong>*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*      <div style={{marginRight: "4rem", marginBottom: "1rem"}}>*/}
            {/*        <Field*/}
            {/*            name="amount"*/}
            {/*            render={() => (*/}
            {/*                <TextField*/}
            {/*                    placeholder={t("app.main.schedules.payments.amount", "Amount")}*/}
            {/*                    inputProps={{*/}
            {/*                      style: {width: "100%"},*/}
            {/*                    }}*/}
            {/*                    onChange={(e: any) => {*/}
            {/*                      dispatch(*/}
            {/*                          setTheFlightForm({*/}
            {/*                            ...flightForm,*/}
            {/*                            economyPremium: {*/}
            {/*                              ...flightForm.economyPremium,*/}
            {/*                              fareReturn: {*/}
            {/*                                ...flightForm.economyPremium.fareReturn,*/}
            {/*                                amount: e.target.value,*/}
            {/*                              },*/}
            {/*                            },*/}
            {/*                          })*/}
            {/*                      );*/}
            {/*                    }}*/}
            {/*                    type="number"*/}
            {/*                    variant="outlined"*/}
            {/*                    size="small"*/}
            {/*                    name="amount"*/}
            {/*                    value={flightForm.economyPremium?.fareReturn.amount}*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <Field*/}
            {/*          name="luggage"*/}
            {/*          render={() => (*/}
            {/*              <TextField*/}
            {/*                  placeholder={t("app.main.flights.flightPriceTable.fareFlightTable.tableRow5", "luggage")}*/}
            {/*                  style={{width: "70%"}}*/}
            {/*                  inputProps={{*/}
            {/*                    style: {width: "100%"},*/}
            {/*                  }}*/}
            {/*                  onChange={(e: any) => {*/}
            {/*                    dispatch(*/}
            {/*                        setTheFlightForm({*/}
            {/*                          ...flightForm,*/}
            {/*                          economyPremium: {*/}
            {/*                            ...flightForm.economyPremium,*/}
            {/*                            fareReturn: {*/}
            {/*                              ...flightForm.economyPremium.fareReturn,*/}
            {/*                              luggage: e.target.value,*/}
            {/*                            },*/}
            {/*                          },*/}
            {/*                        })*/}
            {/*                    );*/}
            {/*                  }}*/}
            {/*                  type="string"*/}
            {/*                  variant="outlined"*/}
            {/*                  size="small"*/}
            {/*                  name="luggage"*/}
            {/*                  value={flightForm.economyPremium?.fareReturn.luggage}*/}
            {/*              />*/}
            {/*          )}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </TableCell>*/}
            {/*</TableRow>*/}
          </TableBody>
        </Table>
      </TableContainer>
  );
};
