import React, {useEffect, useState} from "react";
import {Button, MenuItem, TextField} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {Field, Form, Formik} from "formik";
import {flightsFormStyles} from "../flights.styles";
import {toggleQuickPanel} from "app/main/shared-components/quick-panel/store";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {AppState} from "app/store";
import {UpdateFlightRequestTypes,} from "app/types/service-types/FlightTypes";
import {AirlineCompany} from "app/types/service-types/AirlineCompaniesTypes";
import {fetchAllAirlines} from "app/store/App/airlines-list.slice";
import {Airport} from "app/types/service-types/AirportServiceTypes";
import {fetchAllAirports} from "app/store/App/airports-list.slice";
import DateUtils from "app/utils/DateUtils";
import FlightSectorsTable from "../flight-prices-table/FlightSectorsTable";
import {updateFlight} from "../store/flights-list/update-flight.slice";
import LoadingForm from "../../shared-components/loading-form/LoadingForm";
import {resetTheFlightForm, setTheFlightForm,} from "../store/flights-list/flight-form.slice";
import {errorMsg, warningMsg} from "../../../utils/MessageUtils";
import * as yup from "yup";
import {fetchAllCurrencies} from "../store/currencies-list.slice";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
    numberOfSeats: yup
        .number()
        .typeError("Duhet të jetë numër")
        .test(
            "min",
            "Numri i ulëseve duhet të jetë 1 apo më shumë",
            (val: any) => val > 0
        ),
    maxOnline: yup
        .number()
        .typeError("Duhet të jetë numër")
        .test(
            "min",
            "Numri i MaxOnline duhet të jetë 1 apo më shumë",
            (val: any) => val > 0
        )
});

const UpdateFlightForm = () => {
    const classes = flightsFormStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {params}: any = useRouteMatch();

    const updateFlightsState = useSelector(
        (state: AppState) => state.flights.flightsList.updateFlight
    );
    const {airlineCompanies} = useSelector(
        (state: AppState) => state.appReducers.airlines
    );
    const airports = useSelector(
        (state: AppState) => state.configurations.airport.airportsList.airports.content
    );
    const flightLoading = useSelector(
        (state: AppState) => state.flights.flight.loading
    );
    const flightForm = useSelector(
        (state: AppState) => state.flights.flightsList.flightForm
    );

    const {currencies} = useSelector((state: AppState) => state.flights.currenciesList);


    const [sectorsInvalidNumberOfSeatsIds, setSectorsInvalidNumberOfSeatsIds] =
        useState<string[]>([]);

    useEffect(() => {
        dispatch(fetchAllCurrencies());
    }, []);

    useEffect(() => {
        dispatch(fetchAllAirlines());
        dispatch(fetchAllAirports());
    }, []);

    const onSubmitFormRequest = (data: UpdateFlightRequestTypes) => {
        // Validating if any of sectors numberOfSeats value is less then 1 and add sector ids to array
        const invalidSectors = flightForm.sectorPrices.filter(
            (item: any) => item.numberOfSeats < 1
        );
        if (invalidSectors.length) {
            const invalidSectorsIds = invalidSectors.map((item: any) => item.id);
            setSectorsInvalidNumberOfSeatsIds(invalidSectorsIds);
            dispatch(warningMsg("Keni gabime në mbushjen e të dhënave"));
            return;
        }

        const departureDate = flightForm.dateOfDeparture;
        const departureTime = flightForm.timeOfDeparture;
        const arrivalTime = flightForm.timeOfArrival;
        const departureDateTime = `${departureDate}T${departureTime}:00`;
        const arrivalDateTime = `${departureDate}T${arrivalTime}:00`;

        if (arrivalDateTime === departureDateTime) {
            dispatch(errorMsg("Arrival time cannot be equal as departure time"));
            return;
        }

        setSectorsInvalidNumberOfSeatsIds([]);
        dispatch(
            updateFlight({
                id: flightForm.id,
                body: {...flightForm},
                onSuccess: () => {
                    dispatch(toggleQuickPanel());
                    dispatch(resetTheFlightForm());
                },
            })
        );
    };

    const handleChange = (e: any) => {
        dispatch(
            setTheFlightForm({
                ...flightForm,
                [e.target.name]: e.target.value,
            })
        );
    };

    if (flightLoading) {
        return <LoadingForm/>;
    }

    return (
        <div className={classes.formWrapper}>
            <div className={classes.formHeader}>
                <h1 style={{color: "#0D3B66"}}>Ndrysho fluturimin:</h1>
            </div>
            <Formik
                initialValues={flightForm}
                validationSchema={validationSchema}
                onSubmit={onSubmitFormRequest}
            >
                {({values, setValues}) => (
                    <Form>
                        <div className={classes.row}>
                            <Field
                                variant="outlined"
                                onChange={(e: any) => {
                                    dispatch(
                                        setTheFlightForm({
                                            ...flightForm,
                                            departureAirportId: e.target.value,
                                            arrivalAirportId: e.target.value === flightForm.arrivalAirportId ? '' : flightForm.arrivalAirportId
                                        })
                                    );
                                }}
                                value={flightForm.departureAirportId}
                                name="departureAirportId"
                                label="Fluturimi nga aeroporti"
                                select
                                as={TextField}
                                required
                            >
                                {airports &&
                                    airports.map((b: Airport, index: number) => (
                                        <MenuItem key={b.id + index} id={b.id} value={b.id}>
                                            {b.name} ({b.code})
                                        </MenuItem>
                                    ))}
                            </Field>
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.arrivalAirportId}
                                name="arrivalAirportId"
                                label="Drejt aeroportit"
                                select
                                as={TextField}
                                required
                            >
                                {airports &&
                                    airports.map((c: Airport, index: number) => {
                                        if (c.id !== flightForm.departureAirportId) {
                                            return <MenuItem key={c.id + index} id={c.id} value={c.id}>
                                                {c.name} ({c.code})
                                            </MenuItem>
                                        }
                                    })}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="baseline"
                                >
                                    <DatePicker
                                        inputVariant="outlined"
                                        disableToolbar
                                        autoOk
                                        variant="inline"
                                        disablePast
                                        required
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label="Koha e fluturimit"
                                        value={DateUtils.toISODate(
                                            flightForm.dateOfDeparture.toString()
                                        )}
                                        onChange={(date) => {
                                            if (date)
                                                handleChange({
                                                    target: {
                                                        name: "dateOfDeparture",
                                                        value: DateUtils.convertDate(date.toISOString()),
                                                    },
                                                });
                                        }}
                                    />
                                    <TimePicker
                                        inputVariant="outlined"
                                        format={"HH:mm"}
                                        orientation={"landscape"}
                                        views={["hours", "minutes"]}
                                        ampm={false}
                                        margin="normal"
                                        label={t("app.main.flights.create.form.field3", "Departure time")}
                                        // variant="outlined"
                                        required
                                        name="timeOfDeparture"
                                        value={DateUtils.toDateObject(
                                            flightForm.dateOfDeparture,
                                            flightForm.timeOfDeparture
                                        )}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(e) => {
                                            const date = new Date();
                                            const nowHour = date.getHours();
                                            const nowMin = date.getMinutes();

                                            const pickedHour = ("0" + e?.getHours()).substr(-2);
                                            const pickedMin = ("0" + e?.getMinutes()).substr(-2);

                                            const isSameDay = DateUtils.isEqualDate(
                                                new Date(`${flightForm.dateOfDeparture}`)
                                            );

                                            if (isSameDay) {
                                                if (Number(pickedHour) < Number(nowHour)) {
                                                    handleChange({
                                                        target: {
                                                            name: "timeOfDeparture",
                                                            value: `${nowHour}:${nowMin}`,
                                                        },
                                                    });
                                                } else if (Number(pickedHour) === Number(nowHour)) {
                                                    if (Number(pickedMin) < Number(nowMin)) {
                                                        handleChange({
                                                            target: {
                                                                name: "timeOfDeparture",
                                                                value: `${nowHour}:${nowMin}`,
                                                            },
                                                        });
                                                    } else {
                                                        handleChange({
                                                            target: {
                                                                name: "timeOfDeparture",
                                                                value: `${pickedHour}:${pickedMin}`,
                                                            },
                                                        });
                                                    }
                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: "timeOfDeparture",
                                                            value: `${pickedHour}:${pickedMin}`,
                                                        },
                                                    });
                                                }
                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: "timeOfDeparture",
                                                        value: `${pickedHour}:${pickedMin}`,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                    <TimePicker
                                        disableToolbar
                                        ampm={false}
                                        format="HH:mm"
                                        orientation={"landscape"}
                                        inputVariant="outlined"
                                        label={t("app.main.flights.create.form.field4", "Arrival time")}
                                        // variant="outlined"
                                        autoComplete="true"
                                        name="timeOfArrival"
                                        required
                                        value={DateUtils.toDateObject(
                                            flightForm.dateOfDeparture,
                                            flightForm.timeOfArrival
                                        )}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            const pickedHour = ("0" + e?.getHours()).substr(-2);
                                            const pickedMin = ("0" + e?.getMinutes()).substr(-2);
                                            handleChange({
                                                target: {
                                                    name: "timeOfArrival",
                                                    value: `${pickedHour}:${pickedMin}`,
                                                },
                                            });
                                        }}
                                    />
                                    {/*<Field*/}
                                    {/*    variant="outlined"*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*    value={flightForm.gate}*/}
                                    {/*    name="gate"*/}
                                    {/*    label="Porta"*/}
                                    {/*    as={TextField}*/}
                                    {/*/>*/}
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className={classes.row}>
                            <Field
                                name="numberOfSeats"
                                render={({field, form}: any) => (
                                    <TextField
                                        type="text"
                                        label="Numri i uleseve"
                                        variant="outlined"
                                        name={"numberOfSeats"}
                                        value={flightForm.numberOfSeats}
                                        error={Boolean(form.errors.numberOfSeats)}
                                        required
                                        onChange={(e: any) => {
                                            setValues({
                                                ...values,
                                                numberOfSeats: e.target.value,
                                            });
                                            handleChange(e);
                                        }}
                                        helperText={form.errors.numberOfSeats}
                                    />
                                )}
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.gepack}
                                name="gepack"
                                label="Gepack"
                                as={TextField}
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.gepackINF}
                                name="gepackINF"
                                label="Gepack INF"
                                as={TextField}
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                name="maxOnline"
                                render={({field, form}: any) => (
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={flightForm.maxOnline}
                                        error={Boolean(form.errors.maxOnline)}
                                        onChange={(e: any) => {
                                            setValues({
                                                ...values,
                                                maxOnline: e.target.value,
                                            });
                                            handleChange(e);
                                        }}
                                        helperText={form.errors.maxOnline}
                                        name={"maxOnline"}
                                        label="Max Online"
                                    />
                                )}
                            />

                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.increasePriceOfOnlineBookingFor}
                                name="increasePriceOfOnlineBookingFor"
                                label="Increase Price Of Online Booking For:"
                                as={TextField}
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.airlineCompanyId}
                                name="airlineCompanyId"
                                label="Operatori i fluturimit"
                                select
                                as={TextField}
                                required
                            >
                                {airlineCompanies &&
                                    airlineCompanies.map((a: AirlineCompany, index: number) => (
                                        <MenuItem key={a.id + index} id={a.id} value={a.id}>
                                            {a.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <Field
                                type='number'
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.taxOfFlight}
                                name="taxOfFlight"
                                label="Taksa e fluturimit: (EUR)"
                                as={TextField}
                                InputProps={{inputProps: ({min: 0})}}
                                required
                            />
                            <Field
                                variant="outlined"
                                onChange={handleChange}
                                value={flightForm.number.toUpperCase()}
                                name="number"
                                label="Numri i fluturimit"
                                as={TextField}
                                required
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                onChange={handleChange}
                                variant='outlined'
                                value={flightForm.currencyId}
                                name='currencyId'
                                required
                                label='Valuta'
                                select
                                style={{minWidth: 120}}
                                as={TextField}
                            >
                                {currencies && currencies.filter(cr => ['EUR', 'CHF'].includes(cr.isoCode)).map((cr: any, idx) => (
                                    <MenuItem key={idx} value={cr.id}>{cr.name} - {cr.isoCode}</MenuItem>
                                ))}
                            </Field>
                        </div>

                        <div className={classes.row}>
                            <FlightSectorsTable
                                sectorsInvalidNumberOfSeatsIds={sectorsInvalidNumberOfSeatsIds}
                            />
                        </div>
                        <div className={classes.row}>
                            <LoadingButton
                                type="submit"
                                btnTitle="Submit"
                                isLoading={updateFlightsState.loading}
                            />
                            <Button
                                onClick={(e) => {
                                    dispatch(toggleQuickPanel());
                                    history.push("/flights/flights-list");
                                    dispatch(resetTheFlightForm());
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateFlightForm;
