import React, {useState} from "react";
import history from "@history";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import ScheduleFinalTable from "./ScheduleFinalTable";
import ScheduleFinalForm from "./ScheduleFinalForm";
import {Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "app/main/shared-components/loading-button/LoadingButton";
import {clearScheduleData, createSchedule,} from "../store/create-schedule.slice";
import {clearScheduleForm} from "../store/schedule-form.slice";
import {AppState} from "app/store";
import DateUtils from "app/utils/DateUtils";
import ModalDialog from "app/main/shared-components/modal-dialog/ModalDialog";
import {warningMsg} from "../../../utils/MessageUtils";
import {clearFlightResults} from "../store/flight-results.slice";
import {useTranslation} from "react-i18next";

const ScheduleFinal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {selectedFlight, selectedReturnFlight} = useSelector(
        (state: AppState) => state.schedule.flightResults
    );
    const {loading, error} = useSelector(
        (state: AppState) => state.schedule.createSchedule
    );

    const [modal, setModal] = useState(false);

    const isBirthDateValid = (ageCategory: string, date: Date): Boolean => {
        switch (ageCategory) {
            case "ADULT":
                return date <= DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 0);
            case "CHILD":
                return date <= DateUtils.getDateByYearsAgo(3) && date >= DateUtils.addDaysToDate(DateUtils.getDateByYearsAgo(13), 0);
            case "INF":
                return date > DateUtils.removeDaysToDate(DateUtils.getDateByYearsAgo(3), 0) && date <= new Date();
            default:
                return false;
        }
    };

    const areAllTravelersBirthDatesValid = (travellers: any): Boolean => {
        const invalidTravelersBirthdayKeys: string[] = [];

        Object.keys(travellers).map((key: any, idx: number) => {
            const obj = travellers[key];
            const objKeys = Object.keys(travellers[key]);
            const statusKey = objKeys[0];
            const dateOfBirthKey = objKeys[4];

            if (!isBirthDateValid(obj[statusKey], obj[dateOfBirthKey])) {
                invalidTravelersBirthdayKeys.push(key);
            }
        });

        return !(invalidTravelersBirthdayKeys.length > 0);
    };

    const clearAllData = () => {
        dispatch(clearScheduleForm());
        dispatch(clearScheduleData());
        dispatch(clearFlightResults());
    };

    const areAllTravelersValid = (travellers: any) => {
        let isValid = true;

        Object.keys(travellers).forEach((key) => {
            const obj = travellers[key];
            const objKeys = Object.keys(obj);

            const nameKey = objKeys[2];
            const surnameKey = objKeys[3];
            const nationalityKey = objKeys[5];

            const nameValue = obj[nameKey];
            const surnameValue = obj[surnameKey];
            const nationalityValue = obj[nationalityKey];

            const containsNumber = (str: any) => /\d/.test(str);

            if (containsNumber(nameValue) || containsNumber(surnameValue) || containsNumber(nationalityValue)) {
                isValid = false;
            }
        });

        return isValid;
    };

    return (
        <div>
            <ModalDialog
                open={modal}
                title={t("app.main.schedule.scheduleForm.thirdStep", "The following action deletes the data entered so far!")}
                onOk={() => {
                    clearAllData();
                    history.push("/schedule");
                }}
                onCancel={() => setModal(false)}
            />
            <Formik
                initialValues={{}}
                onSubmit={(data: any) => {
                    const {travellers, formData} = data;

                    const containsNumber = /\d/.test(formData.supervisor);
                    const supervisorError = t("app.main.schedule.scheduleFinal.warningMessage.supervisor", "Supervisor field should not contain numbers!");
                    const generalError = t("app.main.schedule.scheduleFinal.warningMessage.passengerData", "You have errors in filling out the data!",);
                    if (containsNumber) {
                        dispatch(warningMsg(supervisorError));
                        return;
                    }

                    if (!areAllTravelersBirthDatesValid(travellers)) {
                        dispatch(warningMsg(generalError));
                        return;
                    }

                    if (!areAllTravelersValid(travellers)) {
                        dispatch(warningMsg(generalError));
                        return;
                    }

                    const resArr = Object.keys(travellers).map((key: any) => ({
                        ...travellers[key],
                    }));

                    const travellersFormated = resArr.map((trav: any) => {
                        const keys = Object.keys(trav);

                        let objToReturn = {
                            id: "",
                            name: "",
                            surname: "",
                            dateOfBirth: "",
                            gender: "MR",
                            nationality: "",
                            ageCategory: "",
                            departureFlightId: selectedFlight?.id || null,
                            returnFlightId: selectedReturnFlight?.id || null,
                            price: {
                                departureFlightPrice: 0,
                                returningFlightPrice: 0,
                            },
                        };

                        keys.forEach((key: any) => {
                            if (key.includes("_name_")) objToReturn.name = trav[key];
                            else if (key.includes("_surname_"))
                                objToReturn.surname = trav[key];
                            else if (key.includes("_dateOfBirth_"))
                                objToReturn.dateOfBirth = DateUtils.convertDate(trav[key]);
                            else if (key.includes("_nationality_"))
                                objToReturn.nationality = trav[key];
                            else if (key.includes("_gender_")) objToReturn.gender = trav[key];
                            else if (key.includes("_ageCategory_"))
                                objToReturn.ageCategory = trav[key];
                            else if (key.includes("_departureFlightPrice_"))
                                objToReturn.price.departureFlightPrice = parseInt(trav[key]);
                            else if (key.includes("_returningFlightPrice_"))
                                objToReturn.price.returningFlightPrice = !isNaN(
                                    parseInt(trav[key])
                                )
                                    ? trav[key]
                                    : 0;
                        });

                        return objToReturn;
                    });

                    dispatch(
                        createSchedule({
                            body: {
                                flightId: selectedFlight?.id,
                                travelers: travellersFormated,
                                ...formData,
                            },
                            onSuccess: () => {
                                clearAllData();
                                history.push("/schedules/schedules-list");
                            },
                        })
                    );
                }}
            >
                {({setSubmitting, setFieldValue, isSubmitting}) => (
                    <Form>
                        <Link to="/schedule/step2">
                            <Button
                                style={{background: "#e9e9e9"}}>{t("app.main.schedule.scheduleForm.returnBackBtn", "Return Back")}</Button>
                        </Link>
                        <br/>
                        <ScheduleFinalTable
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            setSubmitting={setSubmitting}
                        />
                        <br/>
                        <br/>
                        <ScheduleFinalForm
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            setSubmitting={setSubmitting}
                        />
                        <div
                            style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                padding: "5px",
                            }}
                        >
                            <div style={{float: "right"}}>
                                <LoadingButton
                                    btnTitle={t("app.main.schedule.scheduleForm.thirdStep.addReservation", "Reserve")}
                                    type="submit"
                                    isLoading={loading}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ScheduleFinal;
